import React from "react";
import { Layout } from "../components/utilityComponents/layout";
import { graphql, StaticQuery } from "gatsby";
import { DocumentWrapper } from "../components/utilityComponents/documentWrapper";
import { ConferenceSlider } from "../components/conferencePageComponents/conferenceSlider";
import { ImageWithText } from "../components/utilityComponents/imageWithText";
import { useWidth } from "../hooks/useWidth";
import loadable from "@loadable/component";
const Form = loadable(() => import("../components/contactPageComponents/form"));

export default function Sale_konferencyjnePage() {
  const { isDesktop } = useWidth();
  
  return (
    <StaticQuery
      query={graphql`
        {
          strapiConferenceHall {
            id
            sectionWithImage {
              heroImage {
                url
              }
              isLogo
              description
              title
            }
          }
        }
      `}
      render={({ strapiConferenceHall: { sectionWithImage } }) => {
        return (
          <Layout
            customHeader={<ConferenceSlider sliders={sectionWithImage} />}
          >
            <div>
            {sectionWithImage.map((props: any, index: number) => {
              return (
                <ImageWithText
                    {...props}
                    revert={index % 2 == 1 ? true : false}
                    index={index}
                />
              );
            })}
            </div>
            <Form content="sala" disableMap temat="Wynajem sali konferencyjnej" />
          </Layout>
        );
      }}
    />
  );
}
