import React from "react";
import rightPlanMobile from "./../../assets/images/rightPlanMobile.png";
import { Button } from "./button";
import { strapiUrl } from "../../helpers/strapiUrl";
import { useWidth } from "../../hooks/useWidth";
import { SvgBlob } from "../../helpers/svgBlob";
import { Container } from "../../helpers/container";

type ImageWithTextProps = {
  title?: string;
  description?: string;
  heroImage?: any;
  id?: string;
  isLogo?: boolean;
  isButton?: boolean;
  revert?: boolean;
  style?: any;
  index?: number;
};

export const ImageWithText = ({
  style,
  title,
  description,
  heroImage,
  id,
  isLogo,
  isButton,
  revert,
  index,
}: ImageWithTextProps) => {
  const { width, isDesktop } = useWidth();
  return (
    <Container
      style={style}
      className={`imageWithTextContainer${isLogo ? "--first" : ""}`}

    >
      <div   id={id}
        className={`imageWithTextContainer__row  ${
          width > 1200 && revert && "revert"
        } `}
      >
        <div className={`imageWithTextContainer__row--content${isButton ? "--hasButton" : ""}`}>
          <h3 className="imageWithTextContainer__header headerH01">{title}</h3>
          <p style={{textAlign:"justify"}}>{description}</p>
          {isButton && (
              
            <Button
                onClick={() => window.location.href = 'https://koneser.s4honline.pl/'}
                label="Zamów online" size="medium" type="primary" />
          )}
        </div>

        <div
          className={`imageWithTextContainer__row--rightContent${
            isLogo ? "--first" : ""
          } ${isButton ? "image-with-buttons" : ""}`}
        >
          {width < 1200 ? (
            <img
              style={{
                WebkitMaskImage: `url(${rightPlanMobile})`,
              }}
              src={heroImage ? strapiUrl(heroImage.url) : ""}
              className="imageWithTextContainer__row--rightContent--image"
            />
          ) : (
            <SvgBlob
              src={heroImage ? strapiUrl(heroImage.url) : ""}
              revert={revert}
              showLogo={isLogo}
              index={index}
            />
          )}
        </div>
      </div>
    </Container>
  );
};
