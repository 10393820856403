import React from "react";

type svgBlobProps = {
  src?: string;
  revert?: boolean;
  showLogo?: boolean;
  index?: number;
};

export const SvgBlob = ({ revert, src, showLogo, index }: svgBlobProps) => {

  return (
    <svg
      width="874"
      height={showLogo ? "800" : "600"}
      viewBox={showLogo ? "0 0 874 673" : "0 0 874 573"}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id={"mask0_730_6087" + index}
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x={showLogo ? "40" : revert ? "0" : "-20"}
        y="0"
        width="847"
        height={showLogo ? "800" : "600"}
      >
        {showLogo ? (
          <path
            d="M74.5 107.447C74.5 107.447 93.1248 83.4762 107 69.9473C203.567 -24.2088 302.066 172.095 601.5 39.4472C732 -18.3637 835.237 -16.2416 858.5 69.9473C881.763 156.136 902 631.636 778 602.636C481.5 551.136 348.5 626.447 245.5 658.447C142.5 690.447 63.0001 668.447 112 567.447C161 466.447 125 409.447 121 387.447C117 365.447 89.0001 310.947 72.5001 293.947C56.175 277.127 43.2761 236.814 31.3821 199.641L31 198.447C21.4 168.447 56 125.281 74.5 107.447Z"
            fill="#C4C4C4"
          />
        ) : // <path
        //   xmlns="http://www.w3.org/2000/svg"
        //   d="M72.5 107.447C72.5 107.447 91.1248 83.4762 105 69.9473C201.567 -24.2088 300.066 172.095 599.5 39.4472C730 -18.3637 833.237 -16.2416 856.5 69.9473C879.763 156.136 900 631.636 776 602.636C479.5 551.136 346.5 626.447 243.5 658.447C140.5 690.447 61.0001 668.447 110 567.447C159 466.447 123 409.447 119 387.447C115 365.447 87.0001 310.947 70.5001 293.947C54.175 277.127 41.2761 236.814 29.3821 199.641L29 198.447C19.4 168.447 54 125.281 72.5 107.447Z"
        //   fill="#C4C4C4"
        // />
        revert ? (
          <path
            d="M601.468 36.4053C647.394 44.762 700 131.254 700 164.263C700 211.478 674.114 243.234 642.384 293.374C610.653 343.514 615.663 436.692 628.188 456.748C640.714 476.804 667.017 529.869 628.188 553.268C589.36 576.667 495.003 541.568 435.717 519.423C376.431 497.278 261.616 482.654 165.171 491.428C68.7267 500.203 43.6761 536.972 18.2081 438.363C-7.25995 339.754 0.255249 241.562 4.01282 116.212C7.77039 -9.13886 76.2418 -1.19998 121.333 0.889195C166.424 2.97837 292.929 63.9824 381.441 71.9213C469.953 79.8601 555.542 28.0486 601.468 36.4053Z"
            fill="#C4C4C4"
          />
        ) : (
          <path
            d="M96.7757 35.7563C51.6684 43.9641 0 128.914 0 161.335C0 207.709 25.4241 238.898 56.5892 288.145C87.7543 337.391 82.8335 428.907 70.5315 448.606C58.2295 468.305 32.3953 520.424 70.5315 543.406C108.668 566.387 201.343 531.915 259.572 510.164C317.802 488.414 430.57 474.05 525.295 482.668C620.021 491.286 644.625 527.4 669.639 430.549C694.653 333.698 687.272 237.256 683.581 114.14C679.89 -8.97596 612.64 -1.17859 568.352 0.873344C524.065 2.92528 399.815 62.8419 312.881 70.6393C225.947 78.4366 141.883 27.5486 96.7757 35.7563Z"
            fill="#C4C4C4"
          />
        )}
      </mask>
      {/*-128*/}
      <g mask={`url(#mask0_730_6087${index})`}>
        <image
          x={revert ? "-128" : "-100"}
          y="-15.3638"
          width="1084"
          height={showLogo ? "800" : "600"}
          href={src}
        />
      </g>
      {showLogo && !revert && (
        <>
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M125.165 405.239C143.249 391.009 164.619 381.483 187.34 377.523C219.491 372.59 210.98 395.374 210.98 395.374C202.174 415.066 187.042 431.298 167.955 441.529C144.314 455.504 36.1592 520.919 10.864 492.146C-0.956242 478.405 -2.13826 458.323 2.58982 436.244C23.6298 339.824 67.4828 246.693 114.882 160.49C105.071 178.929 -20.696 466.544 34.268 477.583C42.5777 478.856 51.0706 478.213 59.0905 475.704C74.5893 471.114 89.5948 465.017 103.889 457.501C116.706 451.174 129.033 443.912 140.768 435.774C151.525 427.318 203.534 379.402 152.588 395.961C143.423 398.945 134.683 403.09 126.584 408.293L125.165 405.239Z"
            fill="#CDD8EF"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M119.255 413.108C111.596 418.449 104.749 424.856 98.9246 432.133C78.7 456.447 65.3586 485.674 60.2725 516.809C57.5538 543.351 63.5821 563.786 76.7026 564.843C80.9663 564.492 85.1106 563.267 88.8743 561.246C92.6379 559.225 95.9392 556.451 98.57 553.098C109.527 540.126 117.008 524.614 120.319 508.001C125.915 486.305 127.398 463.764 124.693 441.529C124.102 436.361 123.274 431.076 122.21 425.909C121.147 420.741 120.674 418.98 119.728 415.457C119.767 414.792 119.767 414.125 119.728 413.46L119.255 413.108ZM50.1071 515.869C56.453 493.478 66.5639 472.314 80.0122 453.273C85.423 445.562 91.3048 438.189 97.6244 431.194C104.018 423.902 110.889 417.036 118.192 410.641C104.717 363.665 75.2842 320.446 44.3152 279.459C34.6226 265.953 6.01769 231.66 17.0105 182.452C28.0033 133.244 80.0122 100.947 79.4212 100.947C35.45 154.148 27.7669 206.645 69.6105 269.007C86.0406 293.669 97.388 311.873 105.662 327.023C131.526 381.048 143.932 440.461 141.832 500.25C140.68 517.875 136.689 535.203 130.012 551.572C125.866 561.655 120.635 571.263 114.409 580.228C110.508 587.324 104.931 593.372 98.1569 597.852C91.3828 602.332 83.6146 605.11 75.5206 605.947C61.2181 605.947 51.8801 592.911 46.9156 578.701C39.2325 555.682 43.7242 537.948 50.1071 515.869Z"
            fill="#123C88"
          />
        </>
      )}
    </svg>
  );
};
