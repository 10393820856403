import React from "react";
import { Carousel } from "react-responsive-carousel";
import rightWhite from "../../assets/images/rightWhite.svg";
import leftWhite from "../../assets/images/leftWhite.svg";
import { strapiUrl } from "../../helpers/strapiUrl";
import { Navbar } from "../utilityComponents/layoutComponents/navbar";

const renderNext = (clickHandler: any) => (
  <button
    onClick={clickHandler}
    type="button"
    aria-label="next slide / item"
    className="control-arrow control-next"
  >
    <img src={rightWhite} />
  </button>
);

const renderPrevious = (clickHandler: any) => (
  <button
    onClick={clickHandler}
    type="button"
    aria-label="next slide / item"
    className="control-arrow control-prev"
  >
    <img src={leftWhite} />
  </button>
);

const carouselProperties = {
  className: "conferenceSlider__carousel",
  emulateTouch: true,
  renderArrowNext: renderNext,
  renderArrowPrev: renderPrevious,
  showStatus: false,
  
};

export const ConferenceSlider = ({ sliders }: any) => {
  return (
    <>
      <Navbar fixed />
      <Carousel showThumbs={true}  {...carouselProperties}>
        {sliders.map(({ heroImage, title }: any) => {
          return (
            <div
              className="conferenceSlider"
              style={{
                background: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${strapiUrl(
                  heroImage.url
                )}) center no-repeat  `,
              }}
            >
              <h1 className="conferenceSlider__header">{title}</h1>
            </div>
          );
        })}
      </Carousel>
    </>
  );
};
